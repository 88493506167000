var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('main',[_c('div',[(!_vm.isMobile)?_c('router-link',{staticClass:"absolute skip",style:((_vm.beginBoot || _vm.hovering) && 'transform: translateX(-60px); opacity: 0'),attrs:{"to":"/desktop"}},[_c('span',{staticClass:"arrow"},[_vm._v("➔")]),_c('b',[_vm._v("skip")])]):_vm._e(),_c('div',{staticClass:"root",style:(_vm.clickActive && 'transform: scale(0.95)')},[(_vm.isMobile)?_c('div',{staticClass:"mobile-veil absolute flex justify-center align-end fill-screen",staticStyle:{"z-index":"9999","width":"100%","text-align":"center","bottom":"64px","transition":"2s"},style:(_vm.unveilMobile ? 'opacity: 1' : 'opacity: 0')},[_vm._m(0)]):_vm._e(),_c('div',{staticClass:"intro-container flex-center fill-screen",style:(((_vm.clicked
            ? 'background: black'
            : _vm.hovering
            ? 'background: #BCC3C9 !important'
            : 'background: #CFD5DB !important') + ";"))},[((!_vm.isMobile && !_vm.unveil) || _vm.isMobile)?_c('div',{staticClass:"veil absolute flex-center fill-screen",style:(_vm.isMobile &&
            'transform: scale(0.75); animation: mobile-shrink !important;')},[_c('svg',{attrs:{"height":"400","viewBox":"0 0 1400 1000","fill":"none"}},[_c('path',{staticClass:"path1",attrs:{"d":"M100 0V600C100 700 160 900 400 900","stroke":"#000000","stroke-width":"200"}}),_c('path',{staticClass:"path2",attrs:{"d":"M560 300H193.5","stroke":"#000000","stroke-width":"200"}}),_c('path',{staticClass:"path3",attrs:{"d":"M700 0V600C700 700 760 900 1000 900C1240 900 1300 700 1300 600C1300 500 1240 300 1000 300H840","stroke":"#000000","stroke-width":"200"}})])]):_vm._e(),(!_vm.isMobile)?_c('div',{staticClass:"zoomable"},[_c('div',{staticClass:"flex-center window",class:_vm.clicked
                ? 'next window-hover'
                : _vm.boot || _vm.preboot
                ? 'window-hover'
                : 'window flex-center',on:{"mouseenter":_vm.handleMouseEnter,"mouseleave":_vm.handleMouseLeave,"click":_vm.handleClick}},[_c('div',{staticClass:"laptop-aura",style:(_vm.clicked && 'transition-delay: 20s !important')}),_c('div',{staticClass:"laptop"},[_vm._m(1),_c('div',{staticClass:"lid"},[_c('div',{staticClass:"side top"}),_c('div',{staticClass:"side bottom"},[_c('div',{staticClass:"screen",style:(("" + (_vm.popup && 'display: none;')))},[_c('img',{attrs:{"src":("" + (_vm.boot
                          ? require('@/assets/intro/boot.gif')
                          : _vm.preboot
                          ? require('@/assets/intro/static.gif')
                          : require('@/assets/intro/glitch1.gif'))),"width":"100%","height":"100%"}})])]),_c('div',{staticClass:"side right"}),_c('div',{staticClass:"side left"}),_c('div',{staticClass:"side front"}),_c('div',{staticClass:"side back"})])])])]):_vm._e()]),_c('div',{staticClass:"absolute bg-image",class:_vm.clicked ? 'desktop-modal-transition' : 'desktop-modal',style:(((_vm.hovering && 'animation-play-state: paused') + ";"))}),_c('div',{staticClass:"desktop-modal-final absolute bg-image",style:(_vm.desktopReady
            ? "\n      opacity: 1;\n      pointer-events: all;\n      transition: 1600ms cubic-bezier(0.85, 0, 0.15, 1);\n    "
            : "\n      opacity: 0;\n      pointer-events: none;\n      transition: 1600ms cubic-bezier(0.85, 0, 0.15, 1);\n    ")},[_c('Screen',{attrs:{"popup":_vm.popup}})],1)])],1)])}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticStyle:{"max-width":"75vw","letter-spacing":"1px","color":"#9999aa"}},[_c('p',{staticStyle:{"margin-top":"24px"}},[_vm._v(" Hi! 👋🏾 Thanks for visiting my portfolio. ")]),_c('p',{staticStyle:{"margin-top":"24px"}},[_vm._v(" Unfortunately this website is not optimized for mobile devices... ")]),_c('p',{staticStyle:{"margin-top":"24px"}},[_vm._v("Apologies for the inconvenience")])])},function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"base"},[_c('div',{staticClass:"side top"}),_c('div',{staticClass:"side bottom"}),_c('div',{staticClass:"side right"}),_c('div',{staticClass:"side left"}),_c('div',{staticClass:"side front"}),_c('div',{staticClass:"side back"})])}]

export { render, staticRenderFns }